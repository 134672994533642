import {
  getPaddingOnBody,
  getPaddingFromBody,
  getScrollbarWidth,
} from '../../utils/utils';

const searchBar = document.querySelector('.search-bar');
if (searchBar) {
  const searchOverlay = searchBar.querySelector('.search-bar__overlay');
  const searchInput = searchBar.querySelector('.search-bar__input');
  const searchClear = searchBar.querySelector('.search-bar__clear');
  const searchClose = searchBar.querySelector('.search-bar__close');
  const searchResult = searchBar.querySelector('.search-bar__result');

  const clearSearch = () => {
    searchInput.value = '';
    searchClear.classList.remove('search-bar__clear--active');
    searchResult.innerHTML = '';
  };

  searchInput.addEventListener('focus', () => {
    if (window.innerWidth >= 991) {
      searchBar.classList.add('search-bar--active');
    } else {
      searchBar.classList.add('search-bar--active');
      getPaddingOnBody();
      setTimeout(() => {
        searchInput.focus();
      }, 100);
    }
  });

  searchClose.addEventListener('click', (evt) => {
    evt.preventDefault();
    clearSearch();
    getPaddingFromBody();
    searchBar.classList.remove('search-bar--active');
  });

  searchOverlay.addEventListener('click', () => {
    getPaddingFromBody();
    searchBar.classList.remove('search-bar--active');
  });

  searchInput.addEventListener('input', () => {
    if (searchInput.value !== '') {
      searchClear.classList.add('search-bar__clear--active');
    } else {
      searchClear.classList.remove('search-bar__clear--active');
    }
  });

  searchClear.addEventListener('click', (evt) => {
    evt.preventDefault();
    clearSearch();
  });
}
